<template>
    <div class="message-text">
        <template>
            <div class="open-chat">
                <div class="noti-title">
                    <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                    <span>가입 첫주차 혜택 안내</span>
                </div>
                <div class="noti-body">
                    • 신규 회원은 취향 파악을 위해 일주일 동안 프로필을 조금 더 많이 보내 드려요(최대 20명).<br />
                    • 내일, 그리고 {{ bonusProfileDay }}에 두번에 나누어서 프로필을 보내드릴 예정입니다.<br />
                    (연령대, 지역에 따라 보내드리는 프로필 숫자는 다를 수 있습니다.)
                </div>
                <!-- <div class="noti-body">
                <p class="m-b-16">
                    기다리시는 동안 매칭 선호 사항을 먼저 작성해주세요! 이상형 파악에 큰 도움이 됩니다.
                </p>
                <BottomButton
                    @click="onClickMatchPrefer"
                    label="매칭 선호사항 입력하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div> -->
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ActionOnboard',
    props: ['message'],
    computed: {
        bonusProfileDay() {
            const today = new Date()
            const dayAfter4Days = new Date(today.setDate(today.getDate() + 4))
            const weekdays = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
            return weekdays[dayAfter4Days.getDay()]
        },
    },
}
</script>

<style lang="scss" scoped>
.open-chat {
    padding: 0 16px 16px;
    color: #111111;

    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .noti-button {
        margin-top: 16px;
        ::v-deep button {
            height: 40px;
        }
    }
}
</style>
