import { render, staticRenderFns } from "./ActionOnboard.vue?vue&type=template&id=72981881&scoped=true"
import script from "./ActionOnboard.vue?vue&type=script&lang=js"
export * from "./ActionOnboard.vue?vue&type=script&lang=js"
import style0 from "./ActionOnboard.vue?vue&type=style&index=0&id=72981881&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72981881",
  null
  
)

export default component.exports